<template>
  <div class="bg-grey">
    <b-container fluid class="job-listings">
      <!-- <div class="job-search-bar mt-5">
        <div class="selector-wrapper job-selector-wrapper search-block">
          <input
            type="text"
            class="form-control"
            :placeholder="
              $cookies.get('role_id') != 2 ? 'Search jobs' : 'Search Candidates'
            "
            @keyup.enter="getAllJobs"
            v-model="search"
          />
        </div>

        <div
          class="selector-wrapper location-selector-wrapper location-block custom-select-2"
        >
          
          <multiselect
            v-model="selectedCountry"
            placeholder="Select Country"
            label="checkbox"
            track-by="checkbox_id"
            :options="countries"
            @input="onSelectCountry"
            :multiple="false"
          />
        </div>

        <div class="search-button">
          <button class="btn btn-job-search" @click="getAllJobs">SEARCH</button>
        </div>
      </div> -->
      <!--End Job Search Bar-->

      <GetAllJobs :reRender="reRender" />
    </b-container>
  </div>
</template>

<script>
import ApplicantJobService from "@/apiServices/ApplicantJobServices";
import ApplicantProfileService from "@/apiServices/ApplicantProfileService";
import ApplyJobService from "@/apiServices/ApplyJobServices";
import LocationService from "@/apiServices/LocationServices";
import NavigatorShare from "vue-navigator-share";
import Multiselect from "vue-multiselect";
import FilterService from "@/apiServices/FilterService";
import GetAllJobs from "./jobs/GetAllJobs.vue";
import { SERVERURL } from "@/config";

import moment from "moment";

export default {
  components: {
    NavigatorShare,
    Multiselect,
    GetAllJobs,
  },
  data() {
    return {
      SERVERURL: SERVERURL,
      name: "",
      nameState: null,
      wfh: null,
      selectedJob: {},
      isMobileFilter: window.innerWidth <= 1400,
      advanceSearch: this.$route.query.type == "advance",
      selectedCity: null,
      selectedCountry: null,
      sort: { label: "Date" },
      jobLength: true,
      jobs: [],
      days: [],
      cities: [],
      countries: [],

      role_id: this.$cookies.get("role_id"),
      city: null,
      totalCount: 0,
      appDetails: null,
      bookmarkFilter: null,
      sortingOptions: [{ label: "Name" }],
      sortOptions: [{ label: "Date" }, { label: "Name" }],
      search: "",
      appliedJobs: [],
      appliedJob: {
        user_id: null,
        job_id: null,
      },
      appliedFilter: { filters: [] },
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        searchText: this.$route.query.search,
        city_id: this.$route.query.city && this.$route.query.city != "" ? this.$route.query.city : null,
        feature: "Y",
        user_id: this.$cookies.get("user_id"),
        role_id: this.$cookies.get("role_id"),
      },
      selected: [],
      filters: [
        { title: "Work From Home Jobs", checked: false },
        { title: "Country", checkbox: [] },
        { title: "City", checkbox: [] },
        { title: "Speciality", checkbox: [] },
        {
          title: "Employment type",
          checkbox: [
            {
              title: "job_type",
              checkbox: "Full Time",
              checkbox_id: "Full Time",
            },
            {
              title: "job_type",
              checkbox: "Part Time",
              checkbox_id: "Part Time",
            },
            { title: "job_type", checkbox: "Remote", checkbox_id: "Remote" },
            {
              title: "job_type",
              checkbox: "Internship",
              checkbox_id: "Internship",
            },
            {
              title: "job_type",
              checkbox: "Contract",
              checkbox_id: "Contract",
            },
          ],
        },
        { title: "Industry", checkbox: [] },
        {
          title: "Salary Range",
          checkbox: [
            {
              title: "salary",
              checkbox: "100 - 500",
              checkbox_id: { min: 100, max: 500 },
            },
            {
              title: "salary",
              checkbox: "500 - 10000",
              checkbox_id: { min: 500, max: 10000 },
            },
            {
              title: "salary",
              checkbox: "10000 - 20000",
              checkbox_id: { min: 10000, max: 20000 },
            },
          ],
        },
        {
          title: "Gender",
          checkbox: [
            {
              title: "gender",
              checkbox: "Male",
              checkbox_id: "Male",
            },
            {
              title: "gender",
              checkbox: "Female",
              checkbox_id: "Female",
            },
            {
              title: "gender",
              checkbox: "Other",
              checkbox_id: "Other",
            },
          ],
        },
        {
          title: "Date Modified",
          checkbox: [
            {
              title: "date_modified",
              checkbox: "1 Day Ago",
              checkbox_id: 1,
            },
            {
              title: "date_modified",
              checkbox: "7 Days Ago",
              checkbox_id: 7,
            },
            {
              title: "date_modified",
              checkbox: "30 Days Ago",
              checkbox_id: 30,
            },
            {
              title: "date_modified",
              checkbox: "All",
              checkbox_id: null,
            },
          ],
        },
        {
          title: "Career Level",
          checkbox: [
            {
              title: "experience",
              checkbox: "0-2 Years",
              checkbox_id: "0-2",
            },
            {
              title: "experience",
              checkbox: "2-4 Years",
              checkbox_id: "2-4",
            },
            {
              title: "experience",
              checkbox: "4-6 Years",
              checkbox_id: "4-6",
            },
            {
              title: "experience",
              checkbox: "6-8 Years",
              checkbox_id: "6-8",
            },
            {
              title: "experience",
              checkbox: "8-10 Years",
              checkbox_id: "8-10",
            },
            {
              title: "experience",
              checkbox: "10+ Years",
              checkbox_id: "10+",
            },
          ],
        },
        { title: "Shortlist", checked: false },
      ],
      reRender: false,
    };
  },
  computed: {
    url() {
      return window.location.href;
    },
    title() {
      return "Job";
    },
  },
  watch: {
    // search: function(e) {
    //   this.$router.push({ path: `/job-search?search=${e}` });
    // },
  },
  // beforeRouteUpdate(to, from, next) {
  //   // Call the API query method when the URL changes
  //   this.reRender = true;
  //   next();
  // },
  created() {
    addEventListener("resize", () => {
      this.isMobileFilter = innerWidth <= 1400;
    });
  },

  beforeMount() {
    //this.getAllJobs(this.pagination);
    this.getCities(this.pagination);
    // this.getSkills();
    this.getIndustry();

    this.getCountries();
    this.getApplicantDetails();
  },
  mounted: function () {
    // window.addEventListener("keypress", (e) => {
    //   if (e.key == "Enter") {
    //     this.getAllJobs();
    //   }
    // });
  },
  methods: {
    showFilters: function () {
      document.querySelector(".isMobileFilter").classList.add("show-filter");
      document.querySelector(".navbar").classList.add("navbar-none");
    },

    hideFilters: function () {
      document.querySelector(".isMobileFilter").classList.remove("show-filter");
      document.querySelector(".navbar").classList.remove("navbar-none");
    },
    removeFromFilter() {
      console.log("in remove :>> ");
      this.appliedFilter.filters.map((x, index) => {
        if (x.title == "City2") {
          this.appliedFilter.filters.splice(index, 1);
        }
      });
      console.log("this.appliedFilter.filters :>> ", this.appliedFilter.filters);
    },
    onError(err) {
      console.log(err);
    },
    onSuccess(err) {
      console.log(err);
    },
    showModal() {
      this.$refs.myModal.show();
    },
    hideModal() {
      this.$refs.myModal.hide();
    },

    resetModal() {
      this.selectedJob = {};
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    async onChangeCheckbox(e) {
      console.log("hiting", e);
      let payload = e;
      // const date_mod = payload.filter((f) => f.title == "date_modified");
      // console.log("date_mod :>> ", date_mod);
      // if (date_mod.length > 1) {
      //   this.appliedFilter.filters.pop();
      // }

      const lastElement = payload[payload.length - 1];
      console.log("lastElement :>> ", lastElement);
      

      if (!payload.some((s) => s.title === "Country")) {
        console.log("Country not present");
        this.cities = [];
        this.filters[2].checkbox = null;
        if (this.selectedCountry && !payload.some((s) => s == this.selectedCountry.checkbox_id)) {
          this.selectedCountry = null;
        }
      }
      payload = payload.map((m) => m.checkbox_id);
      if (lastElement && lastElement.title == "Country") {
        let loader = this.$loading.show();
        try {
          const city = await LocationService.GetAllCityOfCountry(payload);
          this.cities = city.data.data;
          this.filters[2].checkbox = this.cities.map((m) => {
            return {
              title: "City",
              checkbox: m.city_name,
              checkbox_id: m.city_id,
            };
          });
          loader.hide();
        } catch (error) {
          loader.hide();
          
        }
      }

      //  let loader = this.$loading.show();
      // try {
      //   const city = await LocationService.GetAllCities(payload);
      //   
      //   // this.cities = city.data.data;
      //   // const c = await FilterService.GetAllCities();
      //   // this.filters[2].checkbox = c.data.data;
      //   loader.hide();
      // } catch (error) {
      //   
      //   loader.hide();
      // }
    },
    handleSubmit() {
      // Exit when the form isn't valid
      // if (!this.checkFormValidity()) {
      //   return;
      // }
      // Push the name to submitted names
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("share-modal");
      });
    },
    getApplicantDetails() {
      const applicant_id = this.$cookies.get("user_id");
      if (this.role_id == 3) {
        let loader = this.$loading.show();
        ApplicantProfileService.getApplicantProfile({ applicant_id })
          .then((res) => {
            this.appDetails = res.data.data.user_data[0];
            if (this.appDetails != undefined) {
              this.appDetails.skills = this.appDetails.skills.map(({ skill_name }) => {
                return skill_name;
              });
            }
            this.getAllJobs(this.pagination);
            // this.hideModal();
            loader.hide();
          })
          .catch((err) => {
            
            loader.hide();
          });
      } else {
        this.getAllJobs(this.pagination);
        //this.hideModal();
      }
    },
    onSelectCity() {
      this.pagination.city_id = null;
    },
    onSelectCountry() {
      const payload = [this.selectedCountry];
      console.log("payload ====:>> ", payload);
      this.appliedFilter.filters = payload[0] ? payload : [];
      console.log("this.appliedFilter.filters =====:>> ", this.appliedFilter.filters);
      this.onChangeCheckbox(this.appliedFilter.filters);
    },
    getAllJobs(paginate = {}) {
      this.getCountries();
      if (Object.keys(paginate).length === 0 && paginate.constructor === Object) {
        this.pagination.current = 1;
        this.pagination.pageSize = 10;
      }
      // if (this.city) {
      //   console.log('this.city :>> ', this.city);
      //   this.appliedFilter.filters.push({
      //     checkbox: this.city.label,
      //     checkbox_id: this.city.city_id,
      //     title: "City",
      //   });
      // }
      // if (this.selectedCity) {
      //   let found = this.appliedFilter.filters.map((f, index) => {
      //     console.log("f :>> ", f);
      //     console.log("index :>> ", index);
      //     if (f.title == "City2") {
      //       return { index };
      //     }
      //   });
      //   console.log("found :>> ", found);
      //   if (found.length == 0) {
      //     this.appliedFilter.filters.push({
      //       city_name: this.selectedCity.city_name,
      //       city_id: this.selectedCity.city_id,
      //       title: "City2",
      //     });
      //   } else {
      //     console.log("found[0].index :>> ", found[0].index);
      //     this.appliedFilter.filters[
      //       found[0].index
      //     ].city_name = this.selectedCity.city_name;
      //     this.appliedFilter.filters[
      //       found[0].index
      //     ].city_id = this.selectedCity.city_id;
      //   }
      // }

      if (this.selectedCity) {
        let index = this.appliedFilter.filters.findIndex((x) => x.title == "City2");

        console.log("index:::: :>> ", index);

        if (index < 0) {
          this.appliedFilter.filters.push({
            city_name: this.selectedCity.city_name,
            city_id: this.selectedCity.city_id,
            title: "City2",
          });
        } else {
          this.appliedFilter.filters[index].city_name = this.selectedCity.city_name;
          this.appliedFilter.filters[index].city_id = this.selectedCity.city_id;
        }
      }
      console.log("this.appliedFilter :>> ", this.appliedFilter);
      let loader = this.$loading.show();
      const wfh = this.wfh;
      console.log(`this.wfh :>>>>`, this.wfh);
      ApplicantJobService.GetAllJobs(
        {
          current: 1,
          pageSize: 10,

          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          searchText: this.search != "" ? this.search : this.pagination.searchText,
          city_id: this.pagination.city_id ? this.pagination.city_id : null,
          feature: "Y",
          // sort_type: this.sort.label,
          role_id: this.$cookies.get("role_id"),
          user_id: this.$cookies.get("user_id"),
          ...paginate,
        },
        {
          filters: this.appliedFilter.filters.length > 0 ? this.appliedFilter.filters : null,
        },
        {
          wfh,
        }
      )
        .then(async (res) => {
          // const data = await res.data.data.forEach((job, index) => {
          //   if (job.job_active === "Y") {
          //     job.applied = true;
          //     job.bookmark = false;
          //     this.jobs.push(job);

          //     //this.getAppliedJobs();
          //   }
          // });
          
          this.jobs = res.data.data.data;
          this.totalCount = res.data.data.totalCount ? res.data.data.totalCount : 0;
          console.log(this.jobs);
          loader.hide();
          this.getAppliedJobs();
          this.jobs = this.jobs.map((m) => {
            return {
              ...m,
              deadline: moment(m.job_end_date).format("DD/MM/YYYY"),
            };
          });
          // this.getAppliedJobs();
          this.daysAgo();
          this.role_id && this.role_id == 3 ? this.getAllBookmark() : null;

          // this.hideModal();
          //this.jobs = [];
          this.jobLength = this.jobs.length > 0 ? true : false;
          if (this.search != "" || (this.appliedFilter && this.appliedFilter.filters.length)) {
            let jobContries = res.data.data.job_countries ? res.data.data.job_countries : [];
            console.log("this.countries ====--:>> ", this.countries);
            console.log(`jobContries:::::::::`, jobContries);
            this.countries = this.countries.filter((f) => {
              const valid = jobContries.some((s) => s == f.checkbox_id);
              console.log("valid :>> ", valid);
              if (valid) {
                return true;
              }
            });
            this.filters[1].checkbox = this.countries;
          }
        })
        .catch((err) => {
          
          loader.hide();
        });
    },
    nextPage() {
      this.pagination.current = this.pagination.current + 1;
      this.jobs = [];

      //this.showModal();
      this.getAllJobs(this.pagination);
      this.sort = { label: "Date" };
    },
    PreviousPage() {
      this.pagination.current = this.pagination.current - 1;
      this.jobs = [];
      //  this.showModal();
      this.getAllJobs(this.pagination);
      this.sort = { label: "Date" };
    },
    // getSkills() {
    //   ApplicantProfileService.getAllSkills().then((skill) => {
    //     this.filters[0].checkbox = skill.data.data;
    //   });
    // },
    sortJobs() {
      if (this.sort.label == "Name") {
        const sorting = function (a, b) {
          if (a.job_title < b.job_title) {
            return -1;
          }
          if (a.job_title > b.job_title) {
            return 1;
          }
          return 0;
        };
        this.jobs.sort(sorting);
      }
      if (this.sort.label == "Date") {
        this.getAllJobs(this.pagination);
        // const sortingDate = function(a, b) {
        //   let date1 = moment(a.created_at);
        //   let date2 = moment(b.created_at);
        //   let diff = date2.diff(date1);
        //   if (date2.isAfter(date1)) {
        //     return 1;
        //   } else {
        //     return -1;
        //   }

        //   return 0;
        // };
        // this.days = this.days.sort(function(a, b) {
        //   return a - b;
        // });
        // this.jobs.sort(sortingDate);
      }
    },
    getAllWfhJobs() {
      // this.pagination.wfh = this.wfh;
      // console.log(`this.wfh`, this.wfh);
      console.log(`this.wfh`, this.wfh);
      //this.getAllJobs(this.pagination);
    },
    getCountries() {
      let loader = this.$loading.show();
      const role_id = this.$cookies.get("role_id");
      FilterService.GetAllCountries({ role_id })
        .then((res) => {
          
          this.countries = res.data.data;
          this.filters[1].checkbox = res.data.data;

          loader.hide();
        })
        .catch((err) => {
          
          loader.hide();
        });
    },
    async getCities(payload) {
      // let loader = this.$loading.show();
      // try {
      //   const city = await LocationService.GetAllCities(payload);
      //   
      //   this.cities = city.data.data;
      //   const c = await FilterService.GetAllCities();
      //   this.filters[2].checkbox = c.data.data;
      //   loader.hide();
      // } catch (error) {
      //   
      //   loader.hide();
      // }
    },

    getIndustry() {
      let loader = this.$loading.show();
      FilterService.GetAllIndustry()
        .then((i) => {
          this.filters[5].checkbox = i.data.data;
          // this.filters[4].checkbox = i.data.data.map((ind) => {
          //   return {
          //     title: "industry",
          //     checkbox: ind.industry_name,
          //     checkbox_id: ind.industry_id,
          //   };
          // });
          loader.hide();
        })
        .catch((err) => {
          
          loader.hide();
        });
    },
    // getCities(payload) {
    //   LocationService.GetAllCities(payload).then((city) => {
    //     this.cities = city.data.data.map((city) => {
    //       return {
    //         label: city.city_name,
    //         city_id: city.city_id,
    //       };
    //     });
    //   });
    // },
    getAllBookmarkJobs() {
      const user_id = this.$cookies.get("user_id");
      if (!this.bookmarkFilter) {
        //  this.showModal();
        this.jobs = [];
        let loader = this.$loading.show();
        ApplicantJobService.GetBookmark({ user_id })
          .then(async (res) => {
            const data = await res.data.data.forEach((job, index) => {
              if (job.job_active === "Y") {
                // job.applied = true;
                job.bookmark = true;
                this.jobs.push(job);
              }
            });
            this.jobs = this.jobs.map((m) => {
              return {
                ...m,
                deadline: moment(m.job_end_date).format("DD/MM/YYYY"),
              };
            });
            loader.hide();
            //  this.getAppliedJobs();
            this.daysAgo();
            this.hideModal();
          })
          .catch((err) => {
            
            loader.hide();
          });
      } else {
        this.jobs = [];
        this.getAllJobs(this.pagination);
        this.hideModal();
      }
    },
    getJobsBookMarkv2() {
      const user_id = this.$cookies.get("user_id");
      console.log("this.bookmarkFilter :>> ", this.bookmarkFilter);
      if (this.bookmarkFilter) {
        console.log("hitting bookmark");
        this.showModal();
        this.jobs = [];
        ApplicantJobService.GetBookmark({ user_id }).then(async (res) => {
          const data = await res.data.data.forEach((job, index) => {
            if (job.job_active === "Y") {
              job.applied = true;
              job.bookmark = true;
              this.jobs.push(job);
            }
          });

          this.jobs = this.jobs.map((m) => {
            return {
              ...m,
              deadline: moment(m.job_end_date).format("DD/MM/YYYY"),
            };
          });
          // this.getAppliedJobs();
          this.daysAgo();
          this.hideModal();
        });
      }
      // else {
      //   this.jobs = [];
      //   this.getAllJobs(this.pagination);
      //   this.hideModal();
      // }
    },

    AddBookmark(job_id, index) {
      const user_id = this.$cookies.get("user_id");
      ApplicantJobService.AddBookmark({ user_id, job_id })
        .then((res) => {
          if (res.data.data.bookmark_active == "Y") {
            this.jobs[index].bookmark = true;
          } else {
            this.jobs[index].bookmark = false;
            this.getJobsBookMarkv2();
          }
        })
        .catch((err) => {
          
          // this.getSingleBookmark(user_id, job_id);
          // this.jobs[index].bookmark = false;
        });
    },

    getAllBookmark() {
      const user_id = this.$cookies.get("user_id");
      ApplicantJobService.GetBookmark({ user_id }).then((res) => {
        const bm = res.data.data;
        this.jobs.forEach((job, index) => {
          bm.forEach((b) => {
            if (job.job_id == b.job_id) {
              this.jobs[index].bookmark = true;
            } else {
              //console.log("hitting :>> ", job.job_id == b.job_id);
              //this.jobs[index].bookmark = false;
            }
          });
        });
      });
    },
    // getQuery() {
    //   this.pagination.searchText = this.search;
    //   this.pagination.city_id = this.city?.city_id;
    //   this.jobs = [];
    //   ApplicantJobService.GetFilteredJobs(this.pagination).then(async (res) => {
    //     this.jobs = [];
    //     const data = await res.data.data.forEach((job, index) => {
    //       if (job.job_active === "Y") {
    //         job.applied = true;
    //         this.jobs.push(job);
    //       }
    //     });
    //     this.getAppliedJobs();
    //   });
    // },
    onApplyJob(id) {
      if (this.$cookies.get("role_id") == 3) {
        this.$router.push({
          name: "Job-Application",
          params: { job_id: id },
        });
      } else {
        this.$router.push({ name: "Register" });
      }
    },
    daysAgo() {
      this.jobs.forEach((job) => {
        let now = moment().format("YYYY-MM-DD");
        let jobCreated = moment(job.job_start_date);
        let diff = jobCreated.diff(now, "day") * -1;
        this.days.push(diff);
      });
    },
    getAppliedJobs() {
      this.pagination.user_id = this.$cookies.get("user_id");
      if (this.$cookies.get("role_id") == 3) {
        let loader = this.$loading.show();
        ApplyJobService.GetAllAppliedJobs(this.pagination)
          .then((res) => {
            this.appliedJobs = res.data.data.data;
            console.log(`this.appliedJobs:::::::::::`, this.appliedJobs);
            this.appliedJobs.forEach((aJob, index) => {
              this.jobs.forEach((j, i) => {
                if (j.job_id == aJob.job_id) {
                  this.jobs[i].applied = false;
                }
              });
            });
            loader.hide();
          })
          .catch((err) => {
            
            loader.hide();
          });
      }
    },
    share(job) {
      this.selectedJob = job;
      this.$bvModal.show("share-modal");
    },
    resetFilters() {
      this.appliedFilter = { filters: [] };
      this.bookmarkFilter = null;
    },
    // appliedFilters() {
    //   this.appliedFilter.user_id = this.$cookies.get("user_id");
    //   this.appliedFilter.role_id = this.$cookies.get("role_id");
    //   Object.assign(this.appliedFilter, this.pagination);
    //   ApplicantJobService.GetAllJobs(this.appliedFilter).then(async (res) => {
    //     this.jobs = [];
    //     this.totalCount = res.data.data.totalCount;
    //     this.bookmarkFilter = false;
    //     const data = await res.data.data.data.forEach((job, index) => {
    //       if (job.job_active === "Y") {
    //         job.applied = true;
    //         this.jobs.push(job);

    //         this.getAppliedJobs();
    //       }
    //     });

    //     this.role_id && this.role_id == 3 ? this.getAllBookmark() : null;
    //   });
    // },
    applyJob(job_id, index) {
      const user_id = this.$cookies.get("user_id");
      const token = this.$cookies.get("AIAPADHASHID");
      const role_id = this.$cookies.get("role_id");
      const payload = { user_id: user_id, job_id: job_id };
      if (token && role_id == 3) {
        ApplyJobService.ApplyJob(payload)
          .then((res) => {
            // this.$bvModal.show("successPage");
            this.jobs[index].applied = true;
            this.$toasted.show("Job Applied Successfully!", {
              position: "top-right",
              duration: 6000,
              icon: "check",
            });
            //this.$router.push({ name: "Job-applied-successfully" });
          })
          .catch((err) => {
            console.log("err ---:>> ", err);

            this.jobs[index].applied = false;
            this.$toasted.show("You have already applied for this job", {
              position: "top-right",
              duration: 6000,
              icon: "clear",
            });
          });
      } else {
        this.$router.push("/register");
      }
    },

    showFilters: function () {
      document.querySelector(".isMobileFilter").classList.add("show-filter");
      document.querySelector(".navbar").classList.add("navbar-none");
    },
    hideFilters: function () {
      document.querySelector(".isMobileFilter").classList.remove("show-filter");
      document.querySelector(".navbar").classList.remove("navbar-none");
    },
  },
};
</script>

<style>
.app-job .share-text {
  align-items: start;
}

.multiselect-dropdown span {
  margin-right: 10px;
}
</style>
